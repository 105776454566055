import React from 'react';
import Image from "next/legacy/image";

const Logo = () => (
  <Image
    width={169}
    height={32}
    src="/images/logo.svg"
    alt="El logo de Anna&Co"
  />
);

export default Logo;
