import clsx from 'clsx';

import classes from './layout.module.scss';

interface GridContainerProps {
  children: React.ReactNode;
  wide?: boolean;
  parentClass?: string;
}

const Grid = ({ children }) => {
  return <div className={classes.grid}>{children}</div>;
};

const GridContainer = ({ children, wide, parentClass }: GridContainerProps) => {
  return (
    <div
      className={clsx(
        classes.gridContainer,
        wide && classes.wide,
        parentClass && parentClass
      )}
    >
      {children}
    </div>
  );
};

export { GridContainer, Grid };
