import * as React from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { IconTrash, IconX } from '@tabler/icons-react';
import { useCartState, useCartDispatch } from '@/context/cart-context';
import { useMinicartState } from '@/context/miniCart';

import { COLORS } from '@/constants/styles';
import { ButtonLink } from '@/components/styled/button';

import '@reach/dialog/styles.css';
import classes from './miniCart.module.scss';

const LineItems = ({ items }): React.ReactElement => {
  const dispatch = useCartDispatch();
  const { lineItems } = useCartState();
  const { setIsOpen } = useMinicartState();

  function remove(id: string) {
    dispatch({ type: 'removeItem', id });

    if (lineItems.length === 1) {
      setIsOpen(false);
    }
  }

  return (
    <ul className={classes.list}>
      {items.map(item => (
        <li className={classes.item} key={item.id}>
          <div className={classes.line}>
            <span className={classes.quantity}>{item.quantity}</span>
            <IconX size={15} stroke={2} className={classes.closeIcon} />
            <span className={classes.name}>{item.name}</span>
          </div>

          <span className={classes.price}>{item.quantity * item.price}€</span>

          <button
            className={classes.removeLineItem}
            onClick={() => remove(item.id)}
          >
            <IconTrash stroke={2} size={16} color={COLORS.dark} />
          </button>
        </li>
      ))}
    </ul>
  );
};

const MiniCart = (): React.ReactElement => {
  const { lineItems } = useCartState();
  const { isOpen, setIsOpen } = useMinicartState();

  return (
    <DialogOverlay
      className={classes.overlay}
      isOpen={isOpen}
      onDismiss={close}
    >
      <DialogContent
        className={classes.overlayContent}
        aria-label="Carrito de la compra"
      >
        <div className={classes.cartWrapper}>
          <button className={classes.close} onClick={() => setIsOpen(false)}>
            <IconX size={20} stroke={2} />
          </button>

          <div className={classes.header}>
            <span className={classes.title}>Tu carrito de la compra</span>
          </div>

          <LineItems items={lineItems} />

          <div className={classes.checkoutLink}>
            <ButtonLink href="/checkout/tus-datos" passHref variant="action">
              Pasar por caja
            </ButtonLink>
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};

export { MiniCart };
