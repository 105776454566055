import * as React from 'react';
import Link from 'next/link';

import classes from './footerLinks.module.scss';

const FooterLinks: React.FC = () => (
  <div className={classes.container}>
    <div className={classes.group}>
      <span className={classes.title}>Empresa</span>
      <Link href="/" passHref className={classes.link}>
        Contacto
      </Link>
      <Link href="/" passHref className={classes.link}>
        Nosotros
      </Link>
    </div>
    <div className={classes.group}>
      <span className={classes.title}>Legal</span>
      <Link href="/aviso-legal" passHref className={classes.link}>
        Aviso legal
      </Link>
      <Link href="/terminos-condiciones" passHref className={classes.link}>
        Terminos y Condiciones
      </Link>
      <Link href="/politica-de-privacidad" passHref className={classes.link}>
        Politica de Privacidad
      </Link>
      <Link href="/politica-de-cookies" passHref className={classes.link}>
        Politica de Cookies
      </Link>
    </div>
  </div>
);

export { FooterLinks };
