import React, { ReactElement } from 'react';
import Link from 'next/link';
import Image from 'next/legacy/image';
import clsx from 'clsx';

import { useUser } from '../../context/user-context';

import classes from './user.module.scss';
import { poppins } from '../styled/text';

const User = (): ReactElement => {
  const { user } = useUser();

  return (
    <>
      {user ? (
        <Link
          className={clsx(classes.profileLink, poppins.className)}
          href="/account"
          passHref
        >
          {user.profile.picture ? (
            <Image
              className={classes.avatar}
              src={user.profile.picture}
              alt=""
            />
          ) : (
            <div className={classes.letterWrapper}>
              <span className={classes.firstLetter}>
                {user?.profile.firstName.charAt(0)}
              </span>
            </div>
          )}
          <span className={classes.name}>{user?.profile.firstName}</span>
        </Link>
      ) : (
        <Link href="/login" className={classes.loginLink} passHref>
          Login
        </Link>
      )}
    </>
  );
};

export default User;
