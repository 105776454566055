'use client';

import React from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';

import { poppins } from '@/components/styled/text';
import styles from './mainMenu.module.scss';

const MainMenu = (): React.ReactElement => {
  const pathname = usePathname();

  return (
    <nav>
      <ul className={styles.menuList}>
        <li className={styles.menuItem}>
          <Link
            href="/"
            className={clsx(
              styles.navLink,
              poppins.className,
              pathname === '/' && styles.isCurrent
            )}
          >
            inicio
          </Link>
        </li>
        {/* <li className={styles.menuItem}>
          <Link
            href="/guias-y-cursos-deco-coaching"
            className={clsx(
              styles.navLink,
              poppins.className,
              pathname === '/guias-y-cursos-deco-coaching' && styles.isCurrent
            )}
          >
            cursos
          </Link>
        </li> */}
        <li className={styles.menuItem}>
          <Link
            href="/blog"
            className={clsx(
              styles.navLink,
              poppins.className,
              pathname === '/blog' && styles.isCurrent
            )}
          >
            articulos
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export { MainMenu };
