import React from 'react';

import { useCartState } from '@/context/cart-context';
import { useMinicartState } from '@/context/miniCart';

import BasicHead from '../Head';
import Header from '../header/Header';
import { AppFooter as Footer } from '../Footer';
import { MiniCart } from '@/components/common/cart/MiniCart';

import classes from './layout.module.scss';

interface Props {
  title?: string;
  tags?: string[];
  loading?: boolean;
  isCommerceOn?: boolean;
  meta?: React.ReactNode;
  preview?: boolean;
  className?: string;
  children: React.ReactElement | React.ReactElement[];
}

const Layout = ({
  children,
  isCommerceOn = true,
  tags = [],
  title = 'Anna&Co',
  meta,

  className,
}: Props) => {
  const { lineItems } = useCartState();
  const { isOpen } = useMinicartState();

  return (
    <>
      <BasicHead {...{ title, tags, meta }} />
      <header className={classes.header}>
        <div className={classes.inner}>
          <Header />
        </div>
      </header>

      <main className={className}>{children}</main>
      <Footer />
      {isCommerceOn && lineItems && lineItems?.length > 0 && isOpen && (
        <MiniCart />
      )}
    </>
  );
};

export default React.memo(Layout);
