import * as React from 'react';
import {
  IconBrandPinterest as PinterestLogo,
  IconBrandFacebook as FacebookLogo,
  IconBrandInstagram as InstagramLogo,
  IconBrandYoutube as YoutubeLogo,
} from '@tabler/icons-react';
import { Copyright } from '@/components/Footer';
import { COLORS } from '@/constants/styles';

import classes from './socialLinks.module.scss';

const SocialLinks: React.FC = () => {
  return (
    <div className={classes.container}>
      <div className={classes.links}>
        <a
          href="https://www.pinterest.com/pinannaandco/"
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <PinterestLogo size={32} color={COLORS.bottle} stroke={1.5} />
        </a>
        <a
          href="https://www.facebook.com/annaandcodecocoaching/"
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookLogo size={32} color={COLORS.bottle} stroke={1.5} />
        </a>
        <a
          href="https://www.instagram.com/annaand_co/"
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramLogo size={32} color={COLORS.bottle} stroke={1.5} />
        </a>
        <a
          href="https://www.youtube.com/channel/UChHfT-BtrF0AHqWMqThrtuw"
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <YoutubeLogo size={32} color={COLORS.bottle} stroke={1.5} />
        </a>
      </div>

      <div className={classes.copyright}>{Copyright}</div>
    </div>
  );
};

export { SocialLinks };
