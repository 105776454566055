import * as React from 'react';
import Image from 'next/legacy/image';

import { Small } from '../styled/text';
import { SocialLinks } from '../common/SocialLinks';
import { FooterLinks } from '../common/FooterLinks';

import classes from './footer.module.scss';

export const Copyright = (
  <Small>&#169;Copyright Anna&Co {new Date().getFullYear()}</Small>
);

const AppFooter = (): React.ReactElement => (
  <footer className={classes.base}>
    <div className={classes.isotipo}>
      <Image
        width={64}
        height={64}
        src="/isotipo.svg"
        alt="Isotipo de Anna&Co"
      />
    </div>
    <SocialLinks />
    <FooterLinks />

    <div className={classes.copyright}>{Copyright}</div>
  </footer>
);

export { AppFooter };
