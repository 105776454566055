'use client';

import * as React from 'react';
import Link from 'next/link';
import { LazyMotion, m, domAnimation } from 'framer-motion';
import { Turn as Hamburger } from 'hamburger-react';

import Logo from '../svg/Logo';
import { MainMenu } from '../MainMenu';
import User from './User';

import classes from './header.module.scss';

const variants = {
  closed: {
    opacity: 0.5,
    height: '48px',
    width: '48px',
    x: 0,
    y: 0,
    borderRadius: 4,
    transition: {
      when: 'afterChildren',
    },
  },
  open: {
    opacity: 1,
    height: '100%',
    width: '100%',
    x: 10,
    y: -10,
    borderRadius: 0,
    transition: {
      delayChildren: 0.4,
    },
  },
};

const menuContent = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};

const Header = (): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <Link href="/" passHref className={classes.logoLink}>
        <Logo />
      </Link>

      <div className={classes.desktopHeaderItem}>
        <MainMenu />
        <div />
      </div>
      <div className={classes.desktopHeaderUser}>
        <User />
      </div>

      <LazyMotion features={domAnimation}>
        <m.div
          className={classes.menuOverlay}
          animate={isOpen ? 'open' : 'closed'}
          variants={variants}
          initial="closed"
        >
          <m.div variants={menuContent} className={classes.content}>
            <Logo />
            <MainMenu />
            <User />
          </m.div>
        </m.div>
      </LazyMotion>

      <div className={classes.toggle}>
        <Hamburger
          toggled={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          size={20}
          duration={0.25}
        />
      </div>
    </>
  );
};

export default Header;
