import * as React from 'react';
import Head from 'next/head';

interface Props {
  title: string;
  tags?: string[];
  meta?: React.ReactNode;
}

const defaultMeta = <React.Fragment />;

const BasicHead: React.FunctionComponent<Props> = ({
  tags = [],
  title,
  meta = defaultMeta,
}) => {
  function renderMeta() {
    return meta;
  }

  return (
    <Head>
      <title>{title}</title>
      {tags.length && <meta name="ac-tags" content={tags.join()} />}
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-32x32.png"
        sizes="32x32"
      ></link>
      {meta && renderMeta()}
    </Head>
  );
};

export default BasicHead;
